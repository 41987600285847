import React, { Component, Fragment } from 'react'
import Arrow from '../Arrow/Arrow'
import styled from 'styled-components'
import { SCREEN_SIZE } from '../../lib/Media'
import ProgressiveImage from '../ProgressiveImage/ProgressiveImage'
import GlobalContext from '../GlobalContext'
import throttle from 'lodash/throttle'
import {
  GTM_ENV_VAR as GEV,
  getNCheckFn as getNCheck,
  SPLIT_EVENT,
} from '../../lib/eventTrackerFunction'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import Router from 'next/router'
import Link from 'next/link'
import {
  trackingTypes,
  createNewObserver,
  impressionTypes,
  triggerImpressionsTracking,
} from '../../lib/impressionsTracker'
import { trackPromoImpressions } from '../../lib/trackClicksAndImpressions'
import {
  trackCitrusBannerClick,
  trackCitrusBannerImpressions,
} from '../../lib/trackCitrus'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import { getLink } from '../../lib/processLink'
import { AMP_EVENT_NAME as AEN, getPageTypeByUrl } from '../../lib/amplitude'
import SessionStorageService from '../../utils/SessionStorageService'

const queue = new IdleQueue()
const DEFAULT_DELAY_MS = 6000
const MAIN_BANNERS = 'main banners'
const MAIN_SUB_BANNERS = 'main sub banners'

const StyledImageSlideShow = styled.div`
  position: relative;
  margin: 0;
  ${SCREEN_SIZE.From.Desktop} {
    width: ${props => (props.hasChildBanner ? 'calc(100% - 332px)' : '100%')};
    display: ${props => (props.hasChildBanner ? 'inline-block' : 'block')};
  }
  ${SCREEN_SIZE.From.Hd} {
    width: ${props =>
      props.hasChildBanner ? 'calc(100% - 26.375rem)' : '100%'};
  }
`

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const CarouselWrapper = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: ${props =>
    props.hasChildBanner
      ? '.5rem'
      : props.withProductCollection
      ? 0
      : '1.75rem'};
  margin-top: ${props => (props.withProductCollection ? 0 : '0.25rem')};

  ${SCREEN_SIZE.From.Desktop} {
    margin-bottom: ${props => (props.withProductCollection ? 0 : '1.75rem')};
  }
  ${SCREEN_SIZE.Only.Mobile} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const ImagesWrp = styled.div`
  width: 100%;
  height: 9rem;
  position: relative;
  ${SCREEN_SIZE.From.MobileL} {
    height: ${props => (props.withProductCollection ? '9rem' : '11.5rem')};
  }

  ${SCREEN_SIZE.From.Tablet} {
    height: ${props => (props.withProductCollection ? '9rem' : '17.625rem')};
  }

  &.smBannerHeight {
    ${SCREEN_SIZE.From.Desktop} {
      height: 17rem;
    }
    ${SCREEN_SIZE.From.Hd} {
      height: 18.75rem;
    }
  }
  &.bigBannerHeight {
    ${SCREEN_SIZE.From.Desktop} {
      height: ${props => (props.withProductCollection ? '9rem' : '25rem')};
    }
  }
  &.childWrap {
    ${SCREEN_SIZE.Below.Desktop} {
      height: 100%;
    }
  }
`

const StyledLink = styled.a`
  display: ${props => (props.active && props.active ? 'block' : 'none')};
  position: absolute;
  transition: opacity 0.1s 0.1s, visibility 0.1s 0.1s;
  visibility: visible;
  opacity: 1;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  top: 0;
  width: 100%;
  height: 100%;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;

  ${SCREEN_SIZE.From.Tablet} {
    background-size: contain;
  }
  border-radius: ${props => (props.withProductCollection ? '0.5rem' : 0)};
  overflow: ${props => (props.withProductCollection ? 'hidden' : 'visible')};
`

const StyledImg = styled.img`
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${SCREEN_SIZE.From.Tablet} {
    background-size: contain;
  }
`

const BannerCarouselIndicatorList = styled.ul`
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  z-index: 1;
  bottom: 0.75rem;
  right: 1rem;
  text-align: ${props => (props.dots && props.dots.align) || 'center'};
`

const BannerCarouselIndicatorItem = styled.li`
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  margin: 0.75rem 0.38rem;
  border-radius: 50%;
  background-color: ${props =>
    props.active ? '#eaeaea' : 'rgba(255, 255, 255, 0.4)'};
  cursor: pointer;
`

const CarouselIndicatorList = styled.ul`
  display: ${props => (props.dots && props.dots.hideDots ? 'none' : 'block')};
  padding: 0;
  margin: 0;
  list-style: none;
  height: auto;
  overflow: hidden;
  text-align: ${props => (props.dots && props.dots.align) || 'center'};
  ${props => props.dots && props.dots.paddingSide} : ${props =>
    props.dots && props.dots.paddingValue};
`

const CarouselIndicatorItem = styled.li`
  width: ${props =>
    props.dots && props.dots.width ? props.dots.width : '0.63rem'};
  height: ${props =>
    props.dots && props.dots.height ? props.dots.height : '0.63rem'};
  display: ${props =>
    props.dots && props.dots.display ? 'inline-block' : 'none'};
  margin: 0.75rem 0.38rem;
  margin-bottom: ${props =>
    props.dots && props.dots.display ? 'inherit' : '2rem'};
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.dots && props.dots.activeColor
      : props.dots && props.dots.inActiveColor};
  cursor: pointer;
`

const ArrowLeft = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 2;
  left: ${({ isBanner }) => (isBanner ? '1rem' : '-1rem')};
  ${SCREEN_SIZE.From.Desktop} {
    display: inherit;
  }
`

const ArrowRight = styled.div`
  display: none;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 2;
  right: ${({ isBanner }) => (isBanner ? '1rem' : '-1rem')};
  left: unset;
  ${SCREEN_SIZE.From.Desktop} {
    display: inherit;
  }
`
const ChildBannerArea = styled.div`
  height: 4.25rem;
  margin-bottom: 1rem;
  ${SCREEN_SIZE.From.MobileL} {
    height: 5.75rem;
  }
  ${SCREEN_SIZE.From.Tablet} {
    height: 9.375rem;
  }
  ${SCREEN_SIZE.From.Desktop} {
    display: inline-block;
    width: 20.25rem;
    height: 100%;
    margin-bottom: 0;
    padding: 0 0 1.75rem 0.5rem;
  }
  ${SCREEN_SIZE.From.Hd} {
    width: 26.375rem;
    padding: 0 0 2.8125rem 0.5rem;
  }
`

const SubBannerWrap = styled.div`
  display: inline-block;
  width: calc(50% - 0.25rem);
  position: relative;
  height: 100%;
  &.firstSub {
    margin-right: 0.5rem;
  }
  ${SCREEN_SIZE.From.Desktop} {
    height: 132px;
    position: relative;
    display: block;
    width: 100%;
    &.firstSub {
      margin: 0 0 0.5rem;
    }
  }
  ${SCREEN_SIZE.From.Hd} {
    height: 146px;
  }
`

class ImageSlideShow extends Component {
  constructor(props) {
    super(props)
    this.bannerRefs = []
    this.setBannerRef = element => {
      this.bannerRefs.push(element)
    }
    this.nextSlide = this.nextSlide.bind(this)
    this.getChildBanners = this.getChildBanners.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleTouchEnd = this.handleTouchEnd.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
    this.resetInterval = this.resetInterval.bind(this)
    this.loadImage = this.loadImage.bind(this)
    this.initImageSlider = this.initImageSlider.bind(this)
    this.initEvent = this.initEvent.bind(this)
    this.handleTrackOnClick = throttle(
      this.handleTrackOnClick.bind(this),
      2000,
      { trailing: false }
    )
    this.createObserver = this.createObserver.bind(this)
    this.trackBannerImpressions = this.trackBannerImpressions.bind(this)
    this.processObserverData = this.processObserverData.bind(this)
    this.getCitrusConfig = this.getCitrusConfig.bind(this)
    this.getPageType = this.getPageType.bind(this)
    this.getScreenName = this.getScreenName.bind(this)
    const { imagesData, subBanner1, subBanner2 } = this.getChildBanners()
    this.state = {
      currentImageIndex: 0,
      trackedBanners: false,
      imagesData,
      subBanner1,
      subBanner2,
      citrusConfig: {},
    }
  }

  getCitrusConfig() {
    let citrusConfig =
      this.props.remoteConfig?.citrusAdConfig?.defaultValue?.value || {}
    citrusConfig =
      typeof citrusConfig === 'string' ? JSON.parse(citrusConfig) : citrusConfig
    return citrusConfig?.enableBannerTracking
  }

  getPageType() {
    const routeName = SessionStorageService.getItem('currentRoute')
    return getPageTypeByUrl(routeName)
  }

  getScreenName() {
    const pageType = this.getPageType()
    switch (pageType) {
      case 'home':
        return 'grocery-online | home'
      case 'promotion':
        return `grocery-online | promotion | ${
          this.props?.config?.url || 'all'
        }`
      default:
        return `grocery-online | ${pageType} | ${this.props?.config?.url}`
    }
  }

  trackBannerImpressions(
    params = {
      action: '',
      prevStoreId: '',
      prevDBPStoreId: '',
    }
  ) {
    if (this.state.enableBannerTracking) {
      trackCitrusBannerImpressions()
    }

    const storeIdToTrack =
      params.prevStoreId ||
      getNCheck(this.props.checkoutAddress, 'clientId', '').toString()
    const dbpStoreIdToTrack =
      params.prevDBPStoreId ||
      getNCheck(this.props.checkoutAddress, 'storeId', '').toString()
    trackPromoImpressions({
      track: this.props.track,
      trackSplit: this.props.trackSplit,
      storeIdToTrack,
      dbpStoreIdToTrack,
      banners: [],
      currentType: impressionTypes.BANNER_IMPRESSION,
      params,
    })

    if (params.action === 'trackOnUpdate' && !this.state.trackedBanners) {
      this.setState({ trackedBanners: true })
    }
  }

  handleTrackOnClick(e, promoToTrack) {
    e.preventDefault()
    this.trackBannerImpressions({ action: 'abandon' })
    const { track, trackSplit } = this.props
    const clickedPromoIndex = this.state.currentImageIndex

    let bannerPos = promoToTrack?.bannerType?.includes('SUB')
      ? `${promoToTrack?.bannerType?.replace('SUB', '')}`
      : `${clickedPromoIndex + 1}`

    //Citrus Ad click tracking
    if (promoToTrack?.adId && this.state.enableBannerTracking) {
      trackCitrusBannerClick({ banner: promoToTrack })
      bannerPos = `${bannerPos}-ct_${promoToTrack.adId}`
    }

    const pageType = this.props.config.pageType
    const ga4lowerCaseType = ['Home', 'Category']
    track({
      [GEV.EVENT]: 'promotionClick',
      [GEV.EVENT_ACTION]: 'promotionclick',
      [GEV.EVENT_CAT]: 'ecommerce-engagement',
      [GEV.ECOMMERCE]: {
        promoClick: {
          promotions: [
            {
              [GEV.ECOMM_ID]: promoToTrack?.bannerType?.includes('SUB')
                ? MAIN_SUB_BANNERS
                : MAIN_BANNERS,
              [GEV.ECOMM_NAME]: promoToTrack.imageUrl,
              [GEV.ECOMM_CREATIVE]: getLink(promoToTrack.link),
              [GEV.ECOMM_POSITION]: bannerPos,
              [GEV.ITEM_ID]: 'internal promotion item id',
              [GEV.ITEM_NAME]: 'internal promotion item name',
              [GEV.ECOMM_LIST]: 'null',
            },
          ],
        },
      },
      [GEV.SNIPPET_VERSION]: 'ga4',
      [GEV.SCREEN_NAME_GA4]: this.getScreenName(),
      [GEV.AMPLITUDE_EVENT_NAME]: AEN.BANNER_CLICKED,
      [GEV.PAGE_TYPE]:
        pageType &&
        typeof pageType === 'string' &&
        ga4lowerCaseType.includes(pageType)
          ? pageType.toLowerCase()
          : pageType,
      [GEV.AMPLITUDE_CTA_LOCATION]: this.props.config?.ctaLocation,
      [GEV.AMPLITUDE_CTA_SUBLOCATION]: this.props.config?.ctaSublocation,
    })

    trackSplit([
      {
        event_type: SPLIT_EVENT,
        [GEV.EVENT]: 'splitPromoClick',
        [GEV.ECOMM_ID]: promoToTrack?.bannerType?.includes('SUB')
          ? MAIN_SUB_BANNERS
          : MAIN_BANNERS,
        [GEV.ECOMM_NAME]: promoToTrack.imageUrl,
        [GEV.ECOMM_CREATIVE]: getLink(promoToTrack.link),
        [GEV.ECOMM_POSITION]: bannerPos,
      },
    ])

    const url = getLink(promoToTrack.link)
    if (url.indexOf('http:') > -1 || url.indexOf('https:') > -1) {
      location.href = url
    } else {
      if (url.includes('/pcm')) {
        Router.pushRoute(url)
      } else {
        Router.push(url)
      }
    }
  }

  handleTouchEnd() {
    const { start, end } = this.state
    if (start && end) {
      // to avoid nextslide call on single touch
      if (start - end < 0) {
        this.nextSlide('next')
      } else {
        this.nextSlide('previous')
      }
    }
    this.setState({
      start: 0,
      end: 0,
    })
  }

  handleTouchStart(event) {
    this.setState({ start: event.touches[0].clientX })
  }

  handleTouchMove(event) {
    this.setState({ end: event.touches[event.touches.length - 1].clientX })
  }

  resetInterval() {
    if (this.props.stopAutoScroll) {
      return
    }
    this.interval && clearInterval(this.interval)
    this.interval = setInterval(
      this.nextSlide,
      this.props.data.delay ? this.props.data.delay : DEFAULT_DELAY_MS
    )
  }

  initEvent() {
    setTimeout(() => {
      if (
        this.props.data &&
        this.props.data.images &&
        this.props.data.images.length > 1
      ) {
        this.resetInterval()
      }
      this.loadImage(0)
    }, 5000)
  }

  initImageSlider() {
    if (document.readyState === 'complete') {
      this.initEvent()
    } else {
      window.addEventListener('load', this.initEvent)
    }
  }

  processObserverData(imagesArr, type = MAIN_BANNERS) {
    const { checkoutAddress } = this.props
    const { enableBannerTracking } = this.state
    imagesArr.forEach((image, index) => {
      const bannerIndex =
        type === MAIN_SUB_BANNERS
          ? image?.bannerType?.replace('SUB', '')
          : index + 1
      const currentRefId = `${checkoutAddress?.clientId}-${
        image?.bannerType
      }-${parseInt(bannerIndex)}`
      const currentBannerRef = this.bannerRefs.find(
        bannerRef => !!bannerRef && bannerRef.dataset.refid === currentRefId
      )
      const productTrackObj = {
        id: type,
        refId: currentRefId,
        name: image.imageUrl,
        creative: getLink(image.link),
        position:
          enableBannerTracking && image?.adId
            ? `${parseInt(bannerIndex)}-ct_${image?.adId}`
            : `${parseInt(bannerIndex)}`,
        adId: image?.adId?.toString() || '',
        list: 'null',
        item_id: 'internal promotion item id', //static value
        item_name: 'internal promotion item name', // static value
      }
      createNewObserver({
        productRef: currentBannerRef,
        trackType: trackingTypes.TRACK_ONCE, // signifies tracked once per page load
        productTrackingData: productTrackObj,
        splitTrackingData: {
          ...productTrackObj,
          event_type: SPLIT_EVENT,
          [GEV.EVENT]: 'splitPromoImpression',
        },
        options: {
          root: null,
          rootMargin: '0px',
          threshold: [0.5, 0], // signifies 50% visibility
        },
        impressionType: impressionTypes.BANNER_IMPRESSION,
        splitImpressionType: impressionTypes.SPLIT_BANNER_IMPRESSION,
      })
    })
  }

  createObserver() {
    const { imagesData, subBanner1, subBanner2 } = this.getChildBanners()
    const subBanners = []
    subBanner1 && subBanners.push(subBanner1)
    subBanner2 && subBanners.push(subBanner2)
    if (!imagesData) {
      return
    }
    if (imagesData?.length && this.bannerRefs.length) {
      this.processObserverData(imagesData, MAIN_BANNERS)
    }
    if (subBanners.length && this.bannerRefs.length) {
      this.processObserverData(subBanners, MAIN_SUB_BANNERS)
    }
    if (this.state.trackedBanners) {
      this.setState({ trackedBanners: false })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.checkoutAddress?.storeId !== this.props.checkoutAddress?.storeId
    ) {
      this.trackBannerImpressions({
        action: 'trackOnUpdate',
        prevStoreId: getNCheck(
          prevProps.checkoutAddress,
          'clientId',
          ''
        ).toString(),
        prevDBPStoreId: getNCheck(
          prevProps.checkoutAddress,
          'storeId',
          ''
        ).toString(),
        page_type: this.getPageType(),
        isGA4BigBanners: true,
        screenName: this.getScreenName(),
      })
    }
    if (prevProps.data?.images !== this.props.data?.images) {
      const { imagesData, subBanner1, subBanner2 } = this.getChildBanners()
      const enableBannerTracking = this.getCitrusConfig()
      this.setState({
        imagesData,
        subBanner1,
        subBanner2,
        currentImageIndex: 0,
        enableBannerTracking,
      })
      this.createObserver()
    }
    if (
      prevState.trackedBanners !== this.state.trackedBanners &&
      this.state.trackedBanners
    ) {
      this.createObserver()
    }
  }

  componentDidMount() {
    queue.pushTask(() => {
      this.initImageSlider()
    })
    queue.pushTask(() => {
      this.createObserver()
    })
    triggerImpressionsTracking.subscribe(
      impressionTypes.BANNER_IMPRESSION,
      data => {
        if (data === impressionTypes.BANNER_IMPRESSION) {
          this.trackBannerImpressions({
            page_type: this.getPageType(),
            isGA4BigBanners: true,
            screenName: this.getScreenName(),
          })
        }
      }
    )

    const enableBannerTracking = this.getCitrusConfig()
    this.setState({ enableBannerTracking })
  }

  componentWillUnmount() {
    this.trackBannerImpressions({
      page_type: this.getPageType(),
      isGA4BigBanners: true,
      screenName: this.getScreenName(),
    })
    this.bannerRefs = []
    if (
      this.props.data &&
      this.props.data.images &&
      this.props.data.images.length > 1
    ) {
      clearInterval(this.interval)
    }
    window.removeEventListener('load', this.initEvent)
    triggerImpressionsTracking.unsubscribe(impressionTypes.BANNER_IMPRESSION)
  }

  loadImage(index) {
    setTimeout(() => {
      if (!this.imageWrapperRef) {
        return
      }

      const elements = this.imageWrapperRef.querySelector('.active')

      if (elements && elements.length > 0) {
        elements.forEach(function (el) {
          el.className = el.className.replace(/\b active\b/, '')
        })
      }

      const currentElement = this.imageWrapperRef.getElementsByClassName(
        `image-${index}`
      )[0]

      if (!currentElement) {
        return
      }

      currentElement.className += ' active'
    })
  }

  getChildBanners() {
    let subBanner1 = null
    let subBanner2 = null
    let info = {}
    const imagesInfo = this.props.data && this.props.data.images
    let imagesData = imagesInfo
    if (imagesInfo && imagesInfo.length) {
      imagesData = imagesInfo.filter(
        item => !item.bannerType || item.bannerType === 'BIG'
      )
      if (imagesInfo.length) {
        subBanner1 = imagesInfo.find(item => item.bannerType === 'SUB1')
        subBanner2 = imagesInfo.find(item => item.bannerType === 'SUB2')
      }
    }
    info = {
      imagesData,
      subBanner1,
      subBanner2,
    }
    return info
  }

  nextSlide(slideTo = 'next') {
    const { imagesData } = this.state
    const lastIndex = imagesData ? imagesData.length - 1 : 0
    this.setState(
      prevState => {
        const { currentImageIndex } = prevState
        let shouldResetIndex = currentImageIndex === lastIndex
        let index = shouldResetIndex ? 0 : currentImageIndex + 1
        if (slideTo === 'previous') {
          shouldResetIndex = currentImageIndex === 0
          index = shouldResetIndex ? lastIndex : currentImageIndex - 1
        }

        return {
          currentImageIndex: index,
        }
      },
      () => {
        this.loadImage(this.state.currentImageIndex)
        this.resetInterval()
      }
    )
  }

  changeSlide(index) {
    this.setState({
      currentImageIndex: index,
    })
    this.resetInterval()
  }

  render() {
    const { imagesData, subBanner1, subBanner2 } = this.state
    const {clickLessImageSlideShow, noResize } = this.props
    if (imagesData?.length === 0) {
      return null
    }
    const { checkoutAddress } = this.props
    const hasChildBanner = subBanner1 || subBanner2
    return (
      <div>
        <StyledImageSlideShow
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
          onTouchEnd={this.handleTouchEnd}
          hasChildBanner={hasChildBanner}
        >
          <CarouselWrapper
            data-testid="image-carousel"
            hasChildBanner={hasChildBanner}
            withProductCollection={this.props.withProductCollection}
          >
            {imagesData &&
              imagesData.length > 1 &&
              this.props.config.isBanner && (
                <BannerCarouselIndicatorList>
                  {imagesData.map((image, index) => (
                    <BannerCarouselIndicatorItem
                      data-testid={`banner-indicator-${index}`}
                      key={index}
                      onClick={() => {
                        this.setState({ currentImageIndex: index }, () => {
                          this.loadImage(index)
                          this.resetInterval()
                        })
                      }}
                      active={index === this.state.currentImageIndex}
                    />
                  ))}
                </BannerCarouselIndicatorList>
              )}
            {imagesData && imagesData.length > 1 && (
              <ArrowLeft
                arrow={this.props.config && this.props.config.arrow}
                isBanner={this.props.config && this.props.config.isBanner}
              >
                <Arrow
                  direction="left"
                  onClick={() => this.nextSlide('previous')}
                  glyph="&#9664;"
                />
              </ArrowLeft>
            )}
            {imagesData && imagesData.length && (
              <ImagesWrp
                className={
                  hasChildBanner ? 'smBannerHeight' : 'bigBannerHeight'
                }
                ref={node => (this.imageWrapperRef = node)}
                withProductCollection={this.props.withProductCollection}
              >
                {imagesData &&
                  imagesData.map((image, index) => {
                    const refId = `${checkoutAddress?.clientId}-${
                      image?.bannerType
                    }-${index + 1}`
                    let srcSetUrl = null
                    if (index === 0) {
                      srcSetUrl = image.imageUrl
                    }

                    return clickLessImageSlideShow ? (
                      <Fragment>
                        {index === this.state.currentImageIndex && (
                          <StyledImg
                            data-testid="image"
                            key={`image-${image.imageUrl}`}
                            src={noResize ? image.imageUrl : `${image.imageUrl}?w=1200&q=70`}
                            srcSet={noResize ? image.imageUrl : `${image.imageUrl}?w=1200&q=70 1200w, ${image.imageUrl}?w=200&q=70 200w, ${image.imageUrl}?w=400&q=70 400w, ${image.imageUrl}?w=800&q=70 800w, ${image.imageUrl}?w=1024&q=70 1024w`}
                            ref={this.setBannerRef}
                            data-refid={refId}
                            data-impressiontype={
                              impressionTypes.BANNER_IMPRESSION
                            }
                          />
                        )}
                      </Fragment>
                    ) : (
                      <StyledLink
                        href={getLink(image.link)}
                        key={`image-${image.imageUrl}`}
                        className={`image-${index}`}
                        onClick={e => this.handleTrackOnClick(e, image)}
                        active={index === this.state.currentImageIndex}
                        data-testid="image"
                        ref={this.setBannerRef}
                        data-refid={refId}
                        data-impressiontype={impressionTypes.BANNER_IMPRESSION}
                        withProductCollection={this.props.withProductCollection}
                      >
                        {index === 0 ? (
                          <StyledImage
                            src={`${image.imageUrl}?q=70`}
                            srcSet={`${srcSetUrl}?q=70 1200w, ${srcSetUrl}?w=200&q=70 200w, ${srcSetUrl}?w=400&q=70 400w, ${srcSetUrl}?w=800&q=70 800w, ${srcSetUrl}?w=1024&q=70 1024w`}
                            alt={image.imageUrl}
                          />
                        ) : (
                          <ProgressiveImage
                            src={image.imageUrl}
                            srcSet={image.imageUrl}
                            alt={image.imageUrl}
                            objectFitContain={false}
                            unoptimized={false}
                          />
                        )}
                      </StyledLink>
                    )
                  })}
              </ImagesWrp>
            )}
            {imagesData && imagesData.length > 1 && (
              <ArrowRight
                arrow={this.props.config && this.props.config.arrow}
                isBanner={this.props.config && this.props.config.isBanner}
              >
                <Arrow
                  direction="right"
                  onClick={() => this.nextSlide('next')}
                  glyph="&#9654;"
                />
              </ArrowRight>
            )}
          </CarouselWrapper>
          {imagesData && imagesData.length > 1 && (
            <CarouselIndicatorList
              dots={this.props.config && this.props.config.dots}
            >
              {imagesData &&
                imagesData.map((image, index) => (
                  <CarouselIndicatorItem
                    key={`list-${image.imageUrl}`}
                    onClick={() => this.changeSlide(index)}
                    active={index === this.state.currentImageIndex}
                    dots={this.props.config && this.props.config.dots}
                  />
                ))}
            </CarouselIndicatorList>
          )}
        </StyledImageSlideShow>
        {hasChildBanner && (
          <ChildBannerArea>
            <ImagesWrp className="childWrap">
              {(subBanner1 || subBanner2) && (
                <SubBannerWrap className="firstSub" data-testid="sub-banner-1">
                  {subBanner1 && (
                    <Link
                      href={getLink(subBanner1.link)}
                      key={`image-${subBanner1.imageUrl}`}
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <StyledLink
                        className={`image-0`}
                        onClick={e => this.handleTrackOnClick(e, subBanner1)}
                        data-testid="image"
                        ref={this.setBannerRef}
                        data-refid={`${checkoutAddress?.clientId}-${subBanner1?.bannerType}-1`}
                        data-impressiontype={impressionTypes.BANNER_IMPRESSION}
                        active={true}
                      >
                        <StyledImage
                          src={`${subBanner1.imageUrl}?w=1200&q=70`}
                          srcSet={`${subBanner1.imageUrl}?w=1200&q=70 1200w, ${subBanner1.imageUrl}?w=200&q=70 200w, ${subBanner1.imageUrl}?w=400&q=70 400w, ${subBanner1.imageUrl}?w=800&q=70 800w, ${subBanner1.imageUrl}?w=1024&q=70 1024w`}
                          alt="Sub Banner One"
                        />
                      </StyledLink>
                    </Link>
                  )}
                </SubBannerWrap>
              )}
              {(subBanner1 || subBanner2) && (
                <SubBannerWrap data-testid="sub-banner-2">
                  {subBanner2 && (
                    <Link
                      href={getLink(subBanner2.link)}
                      key={`image-${subBanner2.imageUrl}`}
                      passHref
                      prefetch={false}
                      legacyBehavior
                    >
                      <StyledLink
                        className={`image-0`}
                        onClick={e => this.handleTrackOnClick(e, subBanner2)}
                        data-testid="image"
                        ref={this.setBannerRef}
                        data-refid={`${checkoutAddress?.clientId}-${subBanner2?.bannerType}-2`}
                        data-impressiontype={impressionTypes.BANNER_IMPRESSION}
                        active={true}
                      >
                        <StyledImage
                          src={`${subBanner2.imageUrl}?w=1200&q=70`}
                          srcSet={`${subBanner2.imageUrl}?w=1200&q=70 1200w, ${subBanner2.imageUrl}?w=200&q=70 200w, ${subBanner2.imageUrl}?w=400&q=70 400w, ${subBanner2.imageUrl}?w=800&q=70 800w, ${subBanner2.imageUrl}?w=1024&q=70 1024w`}
                          alt="Sub Banner Two"
                        />
                      </StyledLink>
                    </Link>
                  )}
                </SubBannerWrap>
              )}
            </ImagesWrp>
          </ChildBannerArea>
        )}
      </div>
    )
  }
}

const ImageSlideShowWrap = props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <EventTrackingConsumer>
            {({ track, trackSplit }) => (
              <ImageSlideShow
                {...props}
                track={track}
                trackSplit={trackSplit}
                checkoutAddress={checkoutAddress}
                remoteConfig={remoteConfig}
              />
            )}
          </EventTrackingConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
)

ImageSlideShowWrap.defaultProps = {
  config: {},
}

export default ImageSlideShowWrap
